<!--
 * @Descripttion:
 * @Author: 宋爽爽
 * @Date: 2020-03-14 14:00:32
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-05-15 14:04:38
 -->
<template>
  <el-dialog
    :title="title"
    :visible.sync="payVisible"
    width="758px"
    :show-close="false"
    :close-on-click-modal="false"
    append-to-body
    destroy-on-close
    custom-class="pay-dialog"
    @opened="dialogOpened"
    @closed="dialogClosed"
  >
    <div
      v-show="title == '支付宝支付提示'"
      class="je-circle-fade"
    >
      <div class="je-circle-fade-dot" />
      <div class="je-circle-fade-dot" />
      <div class="je-circle-fade-dot" />
      <div class="je-circle-fade-dot" />
      <div class="je-circle-fade-dot" />
      <div class="je-circle-fade-dot" />
      <div class="je-circle-fade-dot" />
      <div class="je-circle-fade-dot" />
      <div class="je-circle-fade-dot" />
      <div class="je-circle-fade-dot" />
      <div class="je-circle-fade-dot" />
      <div class="je-circle-fade-dot" />
    </div>
    <div
      v-show="title == '微信支付提示'"
      class="qrcode"
    >
      <div class="timelimit">
        距离二维码过期还剩
        <span>{{ rocallTime }}</span>。
      </div>
      <span
        class="qrimg"
        style="width: 200px;height: 200px;"
      >
        <img
          :src="'/jepaas/document/preview?fileKey=' + qrcodeImg"
          alt="加载失败"
        >
      </span>
    </div>
    <div
      v-show="title == '微信支付提示'"
      class="refreshbtn"
    >
      <el-button
        v-if="showrefreshButton"
        type="text"
        :loading="refreshLoading"
        icon="el-icon-refresh"
        @click="refreshQrcode"
      >
        立即刷新
      </el-button>
      <span
        v-else
        class="retime el-button"
      >
        {{ nextrefreshTime }}
        <span>后可再次刷新</span>
      </span>
    </div>
    <div
      class="timelimit"
      :style="{ 'margin-top': title == '微信支付提示' ? 0 : 30 + 'px' }"
    >
      应付金额：
      <span style="font-size: 20px">{{ amountPayable.toFixed(2) }}</span> 元。
    </div>
    <div class="pay-tip">
      支付完成前，请不要关闭此窗口。
      <br>支付完成后，请根据您支付的情况点击下方按钮。
    </div>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="payProblem"
      >付款遇到问题</el-button>
      <el-button
        type="danger"
        size="small"
        :loading="isPaid"
        @click="doPaid"
      > {{ payCountdown ? '等待支付结果' + payCountdown + (payCountdown ? 's' : '') : '我已付款' }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { checkStatus, startPay } from '@/pages/detail/actions/actions';
import { computeTime, setStorage } from '@/util/util';

export default {
  name: 'PayDialog',
  inject: ['productType'],
  props: {
    productTypePay: {
      type: String,
      default: '',
    },
    payUrl: {
      type: Object,
      default() { return {}; },
    },
  },
  data() {
    return {
      payVisible: false,
      qrcodeImg: '',
      checkStatus: false,
      isPaid: false,
      orderId: '',
      orderCode: '',
      title: '微信支付提示',
      amountPayable: 0,
      payTime: '', // 二维码生成时间
      rocallTime: '',
      refreshLoading: false,
      showrefreshButton: true, // 展示立即刷新按钮
      nextrefreshTime: '',
      productTypeProp: '', // 如果是订单页面传递过来的productTypePay， 就不能个更改productTyp的值不然会页面警告
      payCountdown: 0, // 点击我已支付 检查支付状态倒计时
      ids: '', // 白条结算的订单id集合
      isBTJS: '',
    };
  },
  watch: {
    productTypePay(nv) {
      if (nv) {
        this.productTypeProp = nv;
      }
    },
  },
  methods: {
    // 弹窗关闭后
    dialogClosed() {
      if (window.JEwechatpaytimers.minute) {
        clearInterval(window.JEwechatpaytimers.minute);
      }
      if (window.JEwechatpaytimers.second) {
        clearInterval(window.JEwechatpaytimers.second);
      }
    },
    // 打开弹窗后
    dialogOpened() {
      // 重置倒计时
      this.refreshLoading = false;
      this.nextrefreshTime = '';
      this.payTime = '';
      this.rocallTime = '';
      this.payCountdown = 0;
      this.showrefreshButton = true;
      this.payCountdown = 0;
    },
    // 关闭弹窗监听  处理验证是否支付逻辑
    doPaid() {
      // 支付完 查询支付状态
      this.isPaid = true;
      checkStatus({
        orderCode: this.orderCode,
        transactionType: this.isBTJS,
      }).then((result) => {
        this.isPaid = false;
        if (!result.success) {
          return this.$message({
            type: 'error',
            message: result.message || '服务异常！',
          });
        }
        switch (result.obj) {
          // 待支付
          case 'DZF':
            // 倒计时时间置为5秒开始
            this.payCountdown = 5;
            const timer = setInterval(() => {
              this.packCheck(timer);
            }, 1000);
            break;
            // 支付成功
          case 'ZFCG':
            this.isPaid = false;
            this.payVisible = false;
            this.$store.commit('setProductType', this.productTypeProp || this.productType);
            setStorage('local', 'jepaasStore', this.$store.state);
            this.isBTJS && this.$emit('reloadBTJS');
            // 白条结算
            !this.isBTJS && this.$router.push({
              path: '/paySuccess',
            });
            break;
            // 支付失败
          case 'ZFSB':
            this.isPaid = false;
            this.payVisible = false;
            this.$router.push({
              path: '/payFailure',
            });
            break;
            // 已取消
          case 'YQX':
            this.isPaid = false;
            this.payVisible = false;
            this.$router.push({
              path: '/payFailure',
            });
            break;
            // 白条结算成功
          case 'BTJSCG':
            this.isPaid = false;
            this.payVisible = false;
            this.$message({
              type: 'success',
              message: '白条结算成功！',
            });
            this.$emit('refreshList');
            break;
          default:
            break;
        }
      });
    },
    // 封装检查状态请求
    packCheck(timer) {
      // 时间减1
      this.payCountdown--;
      checkStatus({
        orderCode: this.orderCode,
        transactionType: this.isBTJS,
      }).then((res) => {
        this.isPaid = false;
        if (!res.success) {
          return this.$message({
            type: 'error',
            message: res.message || '服务异常！',
          });
        }
        switch (res.obj) {
          // 待支付
          case 'DZF':
            // 若果还剩一秒 就结束轮询
            if (this.payCountdown == 1) {
              this.isPaid = false;
              clearInterval(timer);
              this.payVisible = false;
              this.$router.push({
                path: '/payFailure',
              });
            }
            break;
            // 支付成功
          case 'ZFCG':
            this.isPaid = false;
            clearInterval(timer);
            this.payVisible = false;
            this.$store.commit('setProductType', this.productTypeProp || this.productType);
            setStorage('local', 'jepaasStore', this.$store.state);
            this.$router.push({
              path: '/paySuccess',
            });
            break;
            // 支付失败
          case 'ZFSB':
            this.isPaid = false;
            clearInterval(timer);
            this.payVisible = false;
            this.$router.push({
              path: '/payFailure',
            });
            break;
            // 已取消
          case 'YQX':
            this.isPaid = false;
            clearInterval(timer);
            this.payVisible = false;
            this.$router.push({
              path: '/payFailure',
            });
            break;
            // 白条结算成功
          case 'BTJSCG':
            this.isPaid = false;
            this.payVisible = false;
            this.$message({
              type: 'success',
              message: '白条结算成功！',
            });
            this.$emit('refreshList');
            break;
          default:
            break;
        }
      });
    },
    // 点击付款遇到问题
    payProblem() {
      this.payVisible = false;
      this.$emit('openPhoneDialog');
    },
    // 立即刷新二维码
    refreshQrcode() {
      let productType = '商业证书';
      switch (this.productTypeProp || this.productType) {
        case 'SYZS':
          productType = '商业证书';
          break;
        case 'ZQZJ':
          productType = '增强组件';
          break;
        case 'ZYZL':
          productType = '资源资料';
          break;
        case 'RGFW':
          productType = '人工服务';
          break;
        case 'HYCP':
          productType = '行业产品';
          break;
        default:
          break;
      }
      this.refreshLoading = true;
      this.fullscreenLoading = this.$loading({
        lock: true,
        text: '拼命加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)',
        customClass: 'je-pay-loading',
      });
      startPay({
        orderId: this.orderId,
        productType,
        type: 'wechat',
        ids: this.isBTJS == 'BTJS*' && this.orderCode || this.ids, // 处理白条结算
        configurationId: this.payUrl.shopId || '', // 如果是vip支付就要传递该ID
        transactionType: this.payUrl.transactionType || (productType == '资源资料' && 'ZYZL*') || 'SPZF*',
      }).then((result) => {
        this.fullscreenLoading.close();
        if (!result.success) {
          if (result.errorCode == '5101') {
            return this.$message({
              type: 'error',
              message: '当前支付方式异常，请切换支付方式，或者稍后重试！',
            });
          }
          return this.$message({
            type: 'error',
            message: result.message || '刷新失败！',
          });
        }
        this.qrcodeImg = result.obj.data;
        this.payTime = result.obj.date;
        this.payComputeTime(true);
        this.refreshComputeTime();
      });
    },
    // 支付倒计时
    payComputeTime(bool) {
      computeTime(this.payTime, 10, '已过期', bool, 'minute', (time) => {
        this.rocallTime = time;
      });
    },
    // 刷新按钮状态倒计时
    refreshComputeTime() {
      computeTime(this.payTime, 1, 'OK', false, 'second', (time) => {
        this.nextrefreshTime = time;
        this.showrefreshButton = false;
        if (time == 'OK') {
          this.refreshLoading = false;
          this.showrefreshButton = true;
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import '../../style/common/loading.less';
</style>

<style lang="less">
.pay-dialog {
  .pay-tip {
    text-align: center;
    font-size: 16px;
  }
  .pay-tip-sucess {
    margin-top: 3px;
    text-align: center;
  }
  .el-dialog__title {
    font-weight: 600;
  }
  .el-dialog__body {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .timelimit {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
    span {
      color: #f6474e;
    }
  }
  .qrcode {
    text-align: center;
    .qrimg {
      display: inline-block;
    }
    i {
      color: #1296db;
      font-size: 150px;
      display: inline-block;
      margin-bottom: 20px;
    }
  }
  .refreshbtn {
    text-align: center;
    .retime {
      color: #f6474e;
      border: 0;
      span {
        color: #3f3f3f;
      }
      &:hover {
        background-color: #fff;
        cursor: default;
      }
    }
  }
  .el-dialog__footer {
    text-align: center;
    padding-bottom: 30px;
    .el-button {
      min-width: 140px;
    }
    .el-button + .el-button {
      margin-left: 40px;
    }
  }
}
.je-pay-loading {
  .el-loading-spinner {
    i {
      font-size: 50px;
    }
    .el-loading-text {
      font-size: 24px;
    }
  }
}
</style>
